import React from "react"
import { useTranslation } from "react-i18next"
import { Button, Box } from "@material-ui/core"
import { Link } from "gatsby"
import "../styles/general.css"
import Footer from "./Footer"

import { makeStyles } from "@material-ui/core/styles"
import Img404 from "./imgComponents/img404"

const useStyles = makeStyles(theme => ({
  root: {
    // maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}))

const NotFoundComponent = () => {
  const { t, i18n } = useTranslation()

  const classes = useStyles()

  return (
    <div className="componentsDiv">
      <Box align="center">
        <h1 className="titlesGradientH1">{t("404.notfound")}</h1>
        <h3>{t("404.text")}</h3>
        <Img404 />
        <br />
        <Button
          variant="outlined"
          size="large"
          color="primary"
          component={Link}
          to="/"
        >
          {t("404.goback")}
        </Button>
        <br />
        <br />
      </Box>
      <br />
      <br />
      <Box>
        <Footer />
      </Box>
    </div>
  )
}

export default NotFoundComponent
