import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../i18n"
import NotFoundComponent from "../components/NotFoundComponent"

const NotFoundPage = () => {
  const { t, i18n } = useTranslation()
  const [language, setLanguage] = useState("es")
  const onChangeLanguage = () => {
    i18n.changeLanguage(language)
    if (language === "es") {
      setLanguage("en")
    } else {
      setLanguage("es")
    }
  }

  return (
    <Layout>
      <SEO title={t("title.404")} />
      <NotFoundComponent />
    </Layout>
  )
}

export default NotFoundPage
